export default [
  {
    path: '/prospects',
    name: 'prospects-list',
    props: true,
    component: () => import('@/views/prospects/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des prospects',
      breadcrumb: [
        {
          text: 'Prospects',
        },
        {
          text: 'Liste des Prospects',
          active: true,
        },
      ],
    },
  },
  {

    path: '/prospects/create/:id',
    name: 'prospects-add',
    props: true,
    component: () => import('@/views/prospects/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter un prospects',
      breadcrumb: [
        {
          text: 'Prospects',
        },
        {
          text: 'Ajouter un Prospects',
          active: true,
        },
      ],
    },
  },

]
