export default [
  {
    path: '/payments',
    name: 'payments-list',
    props: true,
    component: () => import('@/views/payments/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Paiements',
      breadcrumb: [
        {
          text: 'Paiements',
        },
        {
          text: 'Liste des Paiements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payments/etat-paiement',
    name: 'payments-etat',
    props: true,
    component: () => import('@/views/payments/EtatPaiement.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Paiements',
      breadcrumb: [
        {
          text: 'Paiements',
        },
        {
          text: 'Liste des Paiements',
          active: true,
        },
      ],
    },
  },
  {

    path: '/payments/create/:id',
    name: 'payments-add',
    props: true,
    component: () => import('@/views/payments/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Paiement',
      breadcrumb: [
        {
          text: 'Paiements',
        },
        {
          text: 'Ajouter Paiement',
          active: true,
        },
      ],
    },
  },

  {

    path: '/payments/edit/:id',
    name: 'v-edit',
    props: true,
    component: () => import('@/views/payments/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Paiement',
      breadcrumb: [
        {
          text: 'Paiements',
        },
        {
          text: 'Modifier Paiement',
          active: true,
        },
      ],
    },
  },
  {

    path: '/payments/show/:id',
    name: 'payments-show',
    props: true,
    component: () => import('@/views/payments/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'List des Paiements',
      breadcrumb: [
        {
          text: 'Paiements',
        },
        {
          text: 'Paiements',
          active: true,
        },
      ],
    },
  },

]
