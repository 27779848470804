export default [
  {
    path: '/academic-years',
    name: 'academic-year-list',
    props: true,
    component: () => import('@/views/academicYears/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des années universitaires',
      breadcrumb: [
        {
          text: 'Années universitaires',
        },
        {
          text: 'Liste des années universitaires',
          active: true,
        },
      ],
    },
  },
  {

    path: '/academic-years/create',
    name: 'academic-year-add',
    props: true,
    component: () => import('@/views/academicYears/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter année universitaire',
      breadcrumb: [
        {
          text: 'Années universitaires',
        },
        {
          text: 'Ajouter année universitaire',
          active: true,
        },
      ],
    },
  },

  {

    path: '/academic-years/edit/:id',
    name: 'academic-year-edit',
    props: true,
    component: () => import('@/views/academicYears/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier année universitaire',
      breadcrumb: [
        {
          text: 'Années universitaires',
        },
        {
          text: 'Modifier année universitaire',
          active: true,
        },
      ],
    },
  },

  /*  {
    path: '/suppliers/edit/:id',
    name: 'supplier-edit',
    props: true,
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Modifier fournisseur',
          active: true,
        },
      ],
    },
  }, */

]
