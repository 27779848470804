export default [
  {

    path: '/information-university/create',
    name: 'information-university-add',
    props: true,
    component: () => import('@/views/informationUniversities/create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter les informations',
      breadcrumb: [
        {
          text: 'Information Universitaire',
        },
        {
          text: 'Ajouter les informations',
          active: true,
        },
      ],
    },
  },

]
