/*= ========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
// import { getAPI } from '@/axios-api'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    permissions: [],
    token: '',
    isAuthenticated: false,
    user: {
    },
    currentAcademicYear: {},
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },

    destroyUser(state) {
      state.user = {}
      state.token = ''
      state.permissions = []
      state.isAuthenticated = false
    },

    setUser(state, user) {
      state.user = user
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setCurrentAcademicYear(state, data) {
      state.currentAcademicYear = data
    },
  },
  getters: {
    loggedIn(state) {
      return state.token != null
    },
  },
  actions: {
    userLogout(context) {
      if (context.getters.loggedIn) {
        context.commit('destroyUser')
        localStorage.clear()
      }
    },
    async userLogin(context, usercredentials) {
      axios.defaults.headers.common.Authorization = ''
      const response = await axios.post('api/authentication/login/', {
        email: usercredentials.email,
        password: usercredentials.password,
      })

      const { token } = response.data
      axios.defaults.headers.common.Authorization = `Token ${token}`
      localStorage.setItem('token', response.data.token)
      context.commit('setToken', token)
      context.commit('setUser', response.data)

      if (response.data.groups) {
        context.commit('setPermissions', response.data.groups.permissions)
      }
      const { data } = await axios.get('api/academic-years/', { params: { state: 'Ouverte' } })
      context.commit('setCurrentAcademicYear', data[0])
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {

  },
  plugins: [createPersistedState()],
})
