export default [
  {
    path: '/teachers',
    name: 'teacher-list',
    props: true,
    component: () => import('@/views/teachers/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des enseingants',
      breadcrumb: [
        {
          text: 'Enseingants',
        },
        {
          text: 'Liste des enseingants',
          active: true,
        },
      ],
    },
  },
  {

    path: '/teachers/create',
    name: 'teacher-add',
    props: true,
    component: () => import('@/views/teachers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter un enseingant',
      breadcrumb: [
        {
          text: 'Enseingants',
        },
        {
          text: 'Ajouter un enseingant',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teachers/edite/:id',
    name: 'teacher-edit',
    props: true,
    component: () => import('@/views/teachers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Enseignant',
      breadcrumb: [
        {
          text: 'Enseignants',
        },
        {
          text: 'Modifier Enseignant',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teachers/show/:id',
    name: 'teacher-show',
    props: true,
    component: () => import('@/views/teachers/show/Informations.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Detail Enseignant',
      breadcrumb: [
        {
          text: 'Enseignants',
        },
        {
          text: 'Detail Enseignant',
          active: true,
        },
      ],
    },
  },
  {
    path: '/presences',
    name: 'list-presences',
    props: true,
    component: () => import('@/views/teachers/presences/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'List Presences',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'List Presences',
          active: true,
        },
      ],
    },
  },

  {
    path: '/presences/create/:id/:subjectId',
    name: 'add-presence',
    props: true,
    component: () => import('@/views/teachers/presences/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Presences',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'Ajouter Presences',
          active: true,
        },
      ],
    },
  },

  {
    path: '/notes',
    name: 'list-notes',
    props: true,
    component: () => import('@/views/teachers/notes/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Notes',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'List Notes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notes/create/asiduite/:id/:subjectIddd',
    name: 'add-notes-asiduite',
    props: true,
    component: () => import('@/views/teachers/notes/assiduite/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Notes',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'Ajouter Notes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notes/show/:id/:subjectid',
    name: 'show-notes',
    props: true,
    component: () => import('@/views/teachers/notes/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Notes',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'Details Notes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/notes/create/ds/:id/:subjectId',
    name: 'add-notes-ds',
    props: true,
    component: () => import('@/views/teachers/notes/ds/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Notes',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'Ajouter Notes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/notes/create/exam/:id/:subjectIdd',
    name: 'add-notes-exam',
    props: true,
    component: () => import('@/views/teachers/notes/exam/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Notes',
      breadcrumb: [
        {
          text: 'Etudents',
        },
        {
          text: 'Ajouter Notes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/subjects',
    name: 'list-subject',
    props: true,
    component: () => import('@/views/teachers/subjects/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Matiéres',
      breadcrumb: [
        {
          text: 'Matiéres',
        },
        {
          text: 'Mes Mtiéres',
          active: true,
        },
      ],
    },
  },

]
