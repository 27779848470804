export default [
  {
    path: '/discounts',
    name: 'discounts-list',
    props: true,
    component: () => import('@/views/discounts/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Remises',
      breadcrumb: [
        {
          text: 'Remise',
        },
        {
          text: 'Liste des Remises',
          active: true,
        },
      ],
    },
  },
  {

    path: '/discounts/create/:id',
    name: 'discount-add',
    props: true,
    component: () => import('@/views/discounts/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Remise',
      breadcrumb: [
        {
          text: 'Remises',
        },
        {
          text: 'Ajouter Remise',
          active: true,
        },
      ],
    },
  },

]
