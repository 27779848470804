export default [
  {
    path: '/teaching-units',
    name: 'teaching-units-list',
    props: true,
    component: () => import('@/views/teachingUnits/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des unités enseignement',
      breadcrumb: [
        {
          text: 'unités enseignement',
        },
        {
          text: 'Liste des unités enseignement',
          active: true,
        },
      ],
    },
  },
  {

    path: '/teaching-units/create',
    name: 'teaching-units-add',
    props: true,
    component: () => import('@/views/teachingUnits/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter unité enseignement',
      breadcrumb: [
        {
          text: 'unités enseignement',
        },
        {
          text: 'Ajouter unité enseignement',
          active: true,
        },
      ],
    },
  },

  {

    path: '/teaching-units/edit/:id',
    name: 'teaching-units-edit',
    props: true,
    component: () => import('@/views/teachingUnits/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier unité enseignement',
      breadcrumb: [
        {
          text: 'unité enseignement',
        },
        {
          text: 'Modifier unité enseignement',
          active: true,
        },
      ],
    },
  },

]
