export default [
  {
    path: '/absences',
    name: 'absences-list',
    props: true,
    component: () => import('@/views/absences/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Absences',
      breadcrumb: [
        {
          text: 'Absences',
        },
        {
          text: 'Liste des Absences',
          active: true,
        },
      ],
    },
  },
  {

    path: '/absences/create',
    name: 'absence-add',
    props: true,
    component: () => import('@/views/absences/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Absence',
      breadcrumb: [
        {
          text: 'Absence',
        },
        {
          text: 'Ajouter Abesence',
          active: true,
        },
      ],
    },
  },

  {

    path: '/absences/edit/:id',
    name: 'absence-edit',
    props: true,
    component: () => import('@/views/absences/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Absence',
      breadcrumb: [
        {
          text: 'Absence',
        },
        {
          text: 'Modifier Absence',
          active: true,
        },
      ],
    },
  },

]
