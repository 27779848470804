export default [
  {
    path: '/programs',
    name: 'programs-list',
    props: true,
    component: () => import('@/views/programs/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Programmes',
      breadcrumb: [
        {
          text: 'Programmes',
        },
        {
          text: 'Liste des  Programmes',
          active: true,
        },
      ],
    },
  },
  {

    path: '/subject/create',
    name: 'subject-add',
    props: true,
    component: () => import('@/views/subjects/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Matière',
      breadcrumb: [
        {
          text: 'Matières',
        },
        {
          text: 'Ajouter Matière',
          active: true,
        },
      ],
    },
  },

  {

    path: '/subject/edit/:id',
    name: 'subject-edit',
    props: true,
    component: () => import('@/views/subjects/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Matière',
      breadcrumb: [
        {
          text: 'Matières',
        },
        {
          text: 'Modifier Matière',
          active: true,
        },
      ],
    },
  },

]
