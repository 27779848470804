export default [
  {
    path: '/employees',
    name: 'employee-list',
    props: true,
    component: () => import('@/views/employees/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Employés',
      breadcrumb: [
        {
          text: 'Employés',
        },
        {
          text: 'Liste des employés',
          active: true,
        },
      ],
    },
  },
  {

    path: '/employees/create',
    name: 'employee-add',
    props: true,
    component: () => import('@/views/employees/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Employé',
      breadcrumb: [
        {
          text: 'Employees',
        },
        {
          text: 'Ajouter employé',
          active: true,
        },
      ],
    },
  },

  /*  {
    path: '/suppliers/edit/:id',
    name: 'supplier-edit',
    props: true,
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Modifier fournisseur',
          active: true,
        },
      ],
    },
  }, */

]
