export default [
  {
    path: '/grades',
    name: 'grades-list',
    props: true,
    component: () => import('@/views/grades/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des grades',
      breadcrumb: [
        {
          text: 'grades',
        },
        {
          text: 'Liste des grades',
          active: true,
        },
      ],
    },
  },
  {

    path: '/grade/create',
    name: 'grade-add',
    props: true,
    component: () => import('@/views/grades/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter grade',
      breadcrumb: [
        {
          text: 'grade',
        },
        {
          text: 'Ajouter grade',
          active: true,
        },
      ],
    },
  },

  {

    path: '/grade/edit/:id',
    name: 'grade-edit',
    props: true,
    component: () => import('@/views/grades/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier grade',
      breadcrumb: [
        {
          text: 'grade',
        },
        {
          text: 'Modifier grade',
          active: true,
        },
      ],
    },
  },

]
