export default [
  {
    path: '/seances',
    name: 'seances-list',
    props: true,
    component: () => import('@/views/seances/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Séances',
      breadcrumb: [
        {
          text: 'Séances',
        },
        {
          text: 'Liste des Seances',
          active: true,
        },
      ],
    },
  },
  {

    path: '/seances/create',
    name: 'seance-add',
    props: true,
    component: () => import('@/views/seances/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Séance',
      breadcrumb: [
        {
          text: 'Séances',
        },
        {
          text: 'Ajouter Séance',
          active: true,
        },
      ],
    },
  },

  {

    path: '/seances/edit/:id',
    name: 'seance-edit',
    props: true,
    component: () => import('@/views/seances/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Séance',
      breadcrumb: [
        {
          text: 'Séances',
        },
        {
          text: 'Modifier Séance',
          active: true,
        },
      ],
    },
  },

]
