export default [
  {
    path: '/parcours',
    name: 'parcours-list',
    props: true,
    component: () => import('@/views/parcours/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Parcours',
      breadcrumb: [
        {
          text: 'Parcours',
        },
        {
          text: 'Liste des Parcours',
          active: true,
        },
      ],
    },
  },
  {

    path: '/parcour/create',
    name: 'parcour-add',
    props: true,
    component: () => import('@/views/parcours/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Parcour',
      breadcrumb: [
        {
          text: 'Parcours',
        },
        {
          text: 'Ajouter Parcour',
          active: true,
        },
      ],
    },
  },

  {

    path: '/parcour/edit/:id',
    name: 'parcour-edit',
    props: true,
    component: () => import('@/views/parcours/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Parcour',
      breadcrumb: [
        {
          text: 'Parcour',
        },
        {
          text: 'Modifier Parcour',
          active: true,
        },
      ],
    },
  },

]
