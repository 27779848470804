export default [
  {
    path: '/weeks',
    name: 'weeks-list',
    props: true,
    component: () => import('@/views/weeks/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Semaines',
      breadcrumb: [
        {
          text: 'Configuration Semaines',
        },
        {
          text: 'Liste des Semaines',
          active: true,
        },
      ],
    },
  },
  {

    path: '/weeks/create',
    name: 'weeks-add',
    props: true,
    component: () => import('@/views/weeks/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Semaine',
      breadcrumb: [
        {
          text: 'Semaines',
        },
        {
          text: 'Ajouter Semaines',
          active: true,
        },
      ],
    },
  },

  {

    path: '/weeks/edit/:id',
    name: 'weeks-edit',
    props: true,
    component: () => import('@/views/weeks/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Semaine',
      breadcrumb: [
        {
          text: 'Semaines',
        },
        {
          text: 'Modifier Semaine',
          active: true,
        },
      ],
    },
  },

]
