export default [
  {
    path: '/JourFerie',
    name: 'holidays-list',
    props: true,
    component: () => import('@/views/holidays/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des JourFerie',
      breadcrumb: [
        {
          text: 'JourFerie',
        },
        {
          text: 'Liste des JourFerie',
          active: true,
        },
      ],
    },
  },
  {

    path: '/JourFerie/create',
    name: 'holidays-add',
    props: true,
    component: () => import('@/views/holidays/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter JourFerie',
      breadcrumb: [
        {
          text: 'JourFerie',
        },
        {
          text: 'Ajouter JourFerie',
          active: true,
        },
      ],
    },
  },

  {

    path: '/JourFerie/edit/:id',
    name: 'holidays-edit',
    props: true,
    component: () => import('@/views/holidays/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Jour Ferie',
      breadcrumb: [
        {
          text: 'JourFerie',
        },
        {
          text: 'Modifier Jour Ferie',
          active: true,
        },
      ],
    },
  },

]
