export default [
  {
    path: '/diplomas',
    name: 'diplomas-list',
    props: true,
    component: () => import('@/views/diplomas/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Diplômes ',
      breadcrumb: [
        {
          text: 'Diplômes',
        },
        {
          text: 'Liste des Diplômes',
          active: true,
        },
      ],
    },
  },
  {

    path: '/diplomas/create',
    name: 'diplomas-add',
    props: true,
    component: () => import('@/views/diplomas/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Diplôme',
      breadcrumb: [
        {
          text: 'Diplômes',
        },
        {
          text: 'Ajouter Diplôme',
          active: true,
        },
      ],
    },
  },

  {

    path: '/diplomas/edit/:id',
    name: 'diplomas-edit',
    props: true,
    component: () => import('@/views/diplomas/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Diplôme',
      breadcrumb: [
        {
          text: 'Diplômes',
        },
        {
          text: 'Modifier Diplôme',
          active: true,
        },
      ],
    },
  },

]
