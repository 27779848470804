export default [
  {
    path: '/distribution-subjects',
    name: 'distribution-subjects-list',
    props: true,
    component: () => import('@/views/distributionSubjects/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Répartition matiére',
      breadcrumb: [
        {
          text: 'matiére',
        },
        {
          text: 'Répartition matiére',
          active: true,
        },
      ],
    },
  },
  {
    path: '/distribution-subjects/create/:id',
    name: 'distribution-subjects-add',
    props: true,
    component: () => import('@/views/distributionSubjects/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Répartition Matiére par group',
      breadcrumb: [
        {
          text: 'Groupes',
        },
        {
          text: 'Répartition Matiére par group',
          active: true,
        },
      ],
    },
  },
  {

    path: '/distribution-subjects/edit/:id',
    name: 'distribution-subjects-edit',
    props: true,
    component: () => import('@/views/distributionSubjects/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Salle',
      breadcrumb: [
        {
          text: 'Salles',
        },
        {
          text: 'Modifier Salle',
          active: true,
        },
      ],
    },
  },

]
