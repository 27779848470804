import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import axios from 'axios'
import employees from './routes/employees'
import roles from './routes/roles'
import teachers from './routes/teachers'
import academicYears from './routes/academicYears'
import students from './routes/students'
import inscriptions from './routes/inscriptions'
import informationUniversities from './routes/informationUniversities'
import departements from './routes/departements'
import diplomas from './routes/diplomas'
import filieres from './routes/filieres'
import banques from './routes/banques'
import parcours from './routes/parcours'
import weeks from './routes/weeks'
import seances from './routes/seances'
import groups from './routes/groups'
import rooms from './routes/rooms'
import teachingUnits from './routes/teachingUnits'
import schoolFees from './routes/schoolFees'
import subjects from './routes/subjects'
import grades from './routes/grades'
import payments from './routes/payments'
import discounts from './routes/discounts'
import prospects from './routes/prospects'
import distributionSubjects from './routes/distributionSubjects'
import distributionSubjectsTeacher from './routes/distributionSubjectsTeacher'
import notes from './routes/notes'
import programs from './routes/programs'
import holidays from './routes/holidays'
import certicates from './routes/certificate'
import absences from './routes/absences'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      props: true,
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'dashboard',
        breadcrumb: [
          {
            text: 'dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/list/emploies',
      name: 'list-emploies',
      component: () => import('@/views/teachers/emploies/calendar/Calendar.vue'),
    },
    {
      path: '/list/emploies',
      name: 'emplois-universitaires',
      component: () => import('@/views/adminCalendar/emploies/calendar/Calendar.vue'),
    },
    {
      path: '/profil',
      name: 'profile',
      component: () => import('@/views/Authentification/Profil.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/login',
      name: 'auth',
      component: () => import('@/views/Authentification/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Authentification/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    ...employees,
    ...roles,
    ...teachers,
    ...academicYears,
    ...students,
    ...inscriptions,
    ...informationUniversities,
    ...departements,
    ...diplomas,
    ...filieres,
    ...banques,
    ...parcours,
    ...weeks,
    ...seances,
    ...groups,
    ...rooms,
    ...teachingUnits,
    ...schoolFees,
    ...subjects,
    ...grades,
    ...payments,
    ...discounts,
    ...prospects,
    ...distributionSubjects,
    ...distributionSubjectsTeacher,
    ...notes,
    ...programs,
    ...holidays,
    ...certicates,
    ...absences,

  ],
})

router.beforeEach((to, from, next) => {
  const { token } = store.state
  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`
  } else {
    axios.defaults.headers.common.Authorization = ''
  }
  if (to.matched.some(record => record.meta.requireLogin) && store.state.isAuthenticated === false) {
    next({ name: 'auth' })
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
