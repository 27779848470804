export default [
  {
    path: '/rooms',
    name: 'rooms-list',
    props: true,
    component: () => import('@/views/rooms/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Salles',
      breadcrumb: [
        {
          text: 'Salles',
        },
        {
          text: 'Liste des Salles',
          active: true,
        },
      ],
    },
  },
  {

    path: '/room/create',
    name: 'room-add',
    props: true,
    component: () => import('@/views/rooms/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Salle',
      breadcrumb: [
        {
          text: 'Salles',
        },
        {
          text: 'Ajouter Salle',
          active: true,
        },
      ],
    },
  },

  {

    path: '/room/edit/:id',
    name: 'room-edit',
    props: true,
    component: () => import('@/views/rooms/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Salle',
      breadcrumb: [
        {
          text: 'Salles',
        },
        {
          text: 'Modifier Salle',
          active: true,
        },
      ],
    },
  },

]
