export default [
  {
    path: '/departements',
    name: 'departements-list',
    props: true,
    component: () => import('@/views/departements/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Departements',
      breadcrumb: [
        {
          text: 'Departements',
        },
        {
          text: 'Liste des Departements',
          active: true,
        },
      ],
    },
  },
  {

    path: '/departements/create',
    name: 'departement-add',
    props: true,
    component: () => import('@/views/departements/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Departements',
      breadcrumb: [
        {
          text: 'Departements',
        },
        {
          text: 'Ajouter Departements',
          active: true,
        },
      ],
    },
  },

  {

    path: '/departements/edit/:id',
    name: 'departement-edit',
    props: true,
    component: () => import('@/views/departements/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Departements',
      breadcrumb: [
        {
          text: 'Departements',
        },
        {
          text: 'Modifier Departements',
          active: true,
        },
      ],
    },
  },

]
