export default [
  {
    path: '/bank',
    name: 'bank-list',
    props: true,
    component: () => import('@/views/banques/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Banques',
      breadcrumb: [
        {
          text: 'Banques',
        },
        {
          text: 'Liste des Banques',
          active: true,
        },
      ],
    },
  },
  {

    path: '/bank/create',
    name: 'bank-add',
    props: true,
    component: () => import('@/views/banques/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter banque',
      breadcrumb: [
        {
          text: 'Banque',
        },
        {
          text: 'Ajouter banque',
          active: true,
        },
      ],
    },
  },

  {

    path: '/bank/edit/:id',
    name: 'bank-edit',
    props: true,
    component: () => import('@/views/banques/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier banque',
      breadcrumb: [
        {
          text: 'Banque',
        },
        {
          text: 'Modifier banque',
          active: true,
        },
      ],
    },
  },

]
