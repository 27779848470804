export default [
  {
    path: '/filieres',
    name: 'filieres-list',
    props: true,
    component: () => import('@/views/filieres/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Filieres',
      breadcrumb: [
        {
          text: 'Filieres',
        },
        {
          text: 'Liste des Filieres',
          active: true,
        },
      ],
    },
  },
  {

    path: '/filiere/create',
    name: 'filiere-add',
    props: true,
    component: () => import('@/views/filieres/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Filiere',
      breadcrumb: [
        {
          text: 'Filieres',
        },
        {
          text: 'Ajouter Filiere',
          active: true,
        },
      ],
    },
  },

  {

    path: '/filiere/edit/:id',
    name: 'filiere-edit',
    props: true,
    component: () => import('@/views/filieres/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Filiere',
      breadcrumb: [
        {
          text: 'Filieres',
        },
        {
          text: 'Modifier Filiere',
          active: true,
        },
      ],
    },
  },

]
