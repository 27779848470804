export default [
  {
    path: '/students',
    name: 'student-list',
    props: true,
    component: () => import('@/views/students/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des etudiants',
      breadcrumb: [
        {
          text: 'Etudiants',
        },
        {
          text: 'Liste des etudiants',
          active: true,
        },
      ],
    },
  },
  {

    path: '/students/create',
    name: 'student-add',
    props: true,
    component: () => import('@/views/students/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter un etudiant',
      breadcrumb: [
        {
          text: 'Etudiants',
        },
        {
          text: 'Ajouter un etudiant',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/show/:id',
    name: 'student-show',
    props: true,
    component: () => import('@/views/students/show/Informations.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Deatil Etudiant',
      breadcrumb: [
        {
          text: 'Etudiants',
        },
        {
          text: 'Deatil Etudiant',
          active: true,
        },
      ],
    },
  },

  /*  {
    path: '/suppliers/edit/:id',
    name: 'supplier-edit',
    props: true,
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Modifier fournisseur',
          active: true,
        },
      ],
    },
  }, */

]
