export default [
  {
    path: '/roles',
    name: 'role-list',
    props: true,
    component: () => import('@/views/roles/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des roles',
      breadcrumb: [
        {
          text: 'roles',
        },
        {
          text: 'Liste des roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/create',
    name: 'role-add',
    props: true,
    component: () => import('@/views/roles/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter role',
      breadcrumb: [
        {
          text: 'roles',
        },
        {
          text: 'Ajouter Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'role-edit',
    props: true,
    component: () => import('@/views/roles/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier roles',
      breadcrumb: [
        {
          text: 'Roles',
        },
        {
          text: 'Modifier roles',
          active: true,
        },
      ],
    },
  },

  {
    path: '/roles/show/:id',
    name: 'role-show',
    props: true,
    component: () => import('@/views/roles/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Droit accés',
      breadcrumb: [
        {
          text: 'roles',
        },
        {
          text: 'Droit accés',
          active: true,
        },
      ],
    },
  },

  /*

  {
    path: '/orders/status',
    name: 'order-status',
    props: true,
    component: () => import('@/views/orders/Status.vue'),
    meta: {
      layout: 'vertical',
      requireLogin: true,
      pageTitle: 'Colis',
      breadcrumb: [
        {
          text: 'Status colis',
          active: true,
        },
      ],
    },
  }, */
]
