export default [
  {
    path: '/inscriptions',
    name: 'inscriptions-list',
    props: true,
    component: () => import('@/views/inscriptions/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des inscriptions',
      breadcrumb: [
        {
          text: 'Inscriptions',
        },
        {
          text: 'Liste des inscriptions',
          active: true,
        },
      ],
    },
  },
  {

    path: '/inscriptions/create/:id',
    name: 'inscription-add',
    props: true,
    component: () => import('@/views/inscriptions/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter un inscription',
      breadcrumb: [
        {
          text: 'Inscriptions',
        },
        {
          text: 'Ajouter un inscription',
          active: true,
        },
      ],
    },
  },

  {
    path: '/inscription/edit/:id',
    name: 'inscription-edit',
    props: true,
    component: () => import('@/views/inscriptions/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Inscriptions',
      breadcrumb: [
        {
          text: 'Inscriptions',
        },
        {
          text: 'Modifier Inscriptions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inscription/assign-group/:id',
    name: 'inscription-assign-group',
    props: true,
    component: () => import('@/views/inscriptions/AssignGroup.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Affecter nouveau groupe',
      breadcrumb: [
        {
          text: 'Inscriptions',
        },
        {
          text: 'Affecter nouveau groupe',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inscription/show/:id',
    name: 'inscription-show',
    props: true,
    component: () => import('@/views/inscriptions/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Deatil nouveau groupe',
      breadcrumb: [
        {
          text: 'Inscriptions',
        },
        {
          text: 'Affecter nouveau groupe',
          active: true,
        },
      ],
    },
  },

]
