export default [
  {
    path: '/Cetrificats',
    name: 'certificats-list',
    props: true,
    component: () => import('@/views/certificates/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Cetrificats',
      breadcrumb: [
        {
          text: 'Cetrificats',
        },
        {
          text: 'Liste des Cetrificats',
          active: true,
        },
      ],
    },
  },
  {

    path: '/Cetrificats/create',
    name: 'certificats-add',
    props: true,
    component: () => import('@/views/certificates/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Cetrificats',
      breadcrumb: [
        {
          text: 'Cetrificats',
        },
        {
          text: 'Ajouter Cetrificats',
          active: true,
        },
      ],
    },
  },

  {

    path: '/Cetrificats/edit/:id',
    name: 'certificats-edit',
    props: true,
    component: () => import('@/views/certificates/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Cetrificats',
      breadcrumb: [
        {
          text: 'Cetrificats',
        },
        {
          text: 'Modifier Cetrificats',
          active: true,
        },
      ],
    },
  },

]
