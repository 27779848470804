export default [
  {
    path: '/notes-principal',
    name: 'principal-notes-list',
    props: true,
    component: () => import('@/views/notes/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Notes',
      breadcrumb: [
        {
          text: 'Notes',
        },
        {
          text: 'Liste des Notes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/note-valider',
    name: 'note-valid',
    props: true,
    component: () => import('@/views/notes/deliberations/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Notes Valider',
      breadcrumb: [
        {
          text: 'Notes',
        },
        {
          text: 'Liste des Notes Valider',
          active: true,
        },
      ],
    },
  },
  {
    path: '/deliperation',
    name: 'principal-deliberations',
    props: true,
    component: () => import('@/views/notes/Deliberation.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Notes',
      breadcrumb: [
        {
          text: 'Notes',
        },
        {
          text: 'Liste des Notes',
          active: true,
        },
      ],
    },
  },
  {

    path: '/notes/create',
    name: 'notes-add',
    props: true,
    component: () => import('@/views/notes/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Note',
      breadcrumb: [
        {
          text: 'Notes',
        },
        {
          text: 'Ajouter Note',
          active: true,
        },
      ],
    },
  },

  {

    path: '/notes/edit/:id',
    name: 'notes-edit',
    props: true,
    component: () => import('@/views/notes/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Note',
      breadcrumb: [
        {
          text: 'Note',
        },
        {
          text: 'Modifier Note',
          active: true,
        },
      ],
    },
  },

]
