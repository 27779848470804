export default [
  {
    path: '/distribution-subjects-teacher',
    name: 'distribution-subjects-teacher-list',
    props: true,
    component: () => import('@/views/distributionSubjectsTeacher/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Répartition des Matières par Enseignant',
      breadcrumb: [
        {
          text: 'matiére',
        },
        {
          text: 'Répartition des Matières par Enseignant',
          active: true,
        },
      ],
    },
  },
  {

    path: '/distribution-subjects-teacher/create/:id',
    name: 'distribution-subjects-teacher-add',
    props: true,
    component: () => import('@/views/distributionSubjectsTeacher/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Répartition Maiére par ensignante',
      breadcrumb: [
        {
          text: 'Groupes',
        },
        {
          text: 'Répartition Maiére par ensignante',
          active: true,
        },
      ],
    },
  },

  {

    path: '/distribution-subjects-teacher/edit/:id',
    name: 'distribution-subjects-teacher-edit',
    props: true,
    component: () => import('@/views/distributionSubjectsTeacher/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Salle',
      breadcrumb: [
        {
          text: 'Salles',
        },
        {
          text: 'Modifier Salle',
          active: true,
        },
      ],
    },
  },

]
